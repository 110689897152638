import React, { Component } from 'react';

class Waterdrops extends Component {

    componentDidUpdate() {
        const svgs = document.getElementsByClassName("waterdrop");
        console.log(svgs);
        for (var i = 0; i < svgs.length; i++) {
            if (i < 3) {
                svgs[i].childNodes[1].setAttribute("fill", "black");
            }
            svgs[i].addEventListener('click', function() {
                for(var j = 0; j < svgs.length; j++) {
                    svgs[j].childNodes[1].setAttribute("fill", "black");
                    if (this == svgs[j]) {
                        for(var k = j + 1; k < svgs.length; k++) {
                            svgs[k].childNodes[1].setAttribute("fill", "gray");
                        }
                        document.getElementById('watering-level').value = j + 1;
                        break;
                    }
                }
            });
        }
    }

    render() {
        return (
            <div>
                <form action="/config/watering" method="post" onSubmit={this.handleSubmit}
                      name="watering">
                    <div className="watering-level-selector text-center">
                        <div className="row">
                            <div className="col-sm-2">
                                <svg className="waterdrop" xmlns="http://www.w3.org/2000/svg"
                                     xmlnsXlink="http://www.w3.org/1999/xlink"
                                     version="1.1" width="80" height="80" viewBox="0 0 24 24">
                                    <path fill="gray"
                                          d="M12,20A6,6 0 0,1 6,14C6,10 12,3.25 12,3.25C12,3.25 18,10 18,14A6,6 0 0,1 12,20Z"/>
                                </svg>
                            </div>
                            <div className="col-sm-2">
                                <svg className="waterdrop" xmlns="http://www.w3.org/2000/svg"
                                     xmlnsXlink="http://www.w3.org/1999/xlink"
                                     version="1.1" width="80" height="80" viewBox="0 0 24 24">
                                    <path fill="gray"
                                          d="M12,20A6,6 0 0,1 6,14C6,10 12,3.25 12,3.25C12,3.25 18,10 18,14A6,6 0 0,1 12,20Z"/>
                                </svg>
                            </div>
                            <div className="col-sm-2">
                                <svg className="waterdrop" xmlns="http://www.w3.org/2000/svg"
                                     xmlnsXlink="http://www.w3.org/1999/xlink"
                                     version="1.1" width="80" height="80" viewBox="0 0 24 24">
                                    <path fill="gray"
                                          d="M12,20A6,6 0 0,1 6,14C6,10 12,3.25 12,3.25C12,3.25 18,10 18,14A6,6 0 0,1 12,20Z"/>
                                </svg>
                            </div>
                            <div className="col-sm-2">
                                <svg className="waterdrop" xmlns="http://www.w3.org/2000/svg"
                                     xmlnsXlink="http://www.w3.org/1999/xlink"
                                     version="1.1" width="80" height="80" viewBox="0 0 24 24">
                                    <path fill="gray"
                                          d="M12,20A6,6 0 0,1 6,14C6,10 12,3.25 12,3.25C12,3.25 18,10 18,14A6,6 0 0,1 12,20Z"/>
                                </svg>
                            </div>
                            <div className="col-sm-2">
                                <svg className="waterdrop" xmlns="http://www.w3.org/2000/svg"
                                     xmlnsXlink="http://www.w3.org/1999/xlink"
                                     version="1.1" width="80" height="80" viewBox="0 0 24 24">
                                    <path fill="gray"
                                          d="M12,20A6,6 0 0,1 6,14C6,10 12,3.25 12,3.25C12,3.25 18,10 18,14A6,6 0 0,1 12,20Z"/>
                                </svg>
                            </div>
                            <div className="col-sm-2">
                                <svg className="waterdrop" xmlns="http://www.w3.org/2000/svg"
                                     xmlnsXlink="http://www.w3.org/1999/xlink"
                                     version="1.1" width="80" height="80" viewBox="0 0 24 24">
                                    <path fill="gray"
                                          d="M12,20A6,6 0 0,1 6,14C6,10 12,3.25 12,3.25C12,3.25 18,10 18,14A6,6 0 0,1 12,20Z"/>
                                </svg>
                            </div>
                        </div>
                        <div className="hideInMobileView">
                            <div className="row">
                                <div className="col-sm-2">15 %</div>
                                <div className="col-sm-2">30 %</div>
                                <div className="col-sm-2">45 %</div>
                                <div className="col-sm-2">60 %</div>
                                <div className="col-sm-2">75 %</div>
                                <div className="col-sm-2">90 %</div>
                            </div>
                            <div className="row">
                                <div className="col-sm-2">Almost no watering, like e.g. cactus</div>
                                <div className="col-sm-2">Small amounts of water</div>
                                <div className="col-sm-2">Medium watering profile, for standard room
                                    plants
                                </div>
                                <div className="col-sm-2">High watering profile</div>
                                <div className="col-sm-2">Very high watering profile</div>
                                <div className="col-sm-2">Massive amount of water, like e.g. swamp plants
                                </div>
                            </div>
                        </div>
                        <input id="watering-level" type="hidden" name="level"/>
                    </div>
                    <button type="submit" id="watering-submit" aria-describedby="wateringInfo">Save
                    </button>
                </form>

            </div>
        );
    }

    handleSubmit(event) {

    }
}

export default Waterdrops;
