import React, { Component } from 'react';

class Admin extends Component {

    render() {
        return (
            <div>
                <div className="form-group">
                    <label htmlFor="logs">Logging:</label>
                    <textarea className="form-control" rows="20" id="logs"></textarea>
                </div>
                <div>
                    <form action="/admin/restart" method="post" onSubmit={this.handleSubmit}>
                        <button className="btn btn-default buttons-inline" type="submit"
                                aria-describedby="restartHelp">Restart
                            HydroPod
                        </button>
                    </form>
                    <form action="/admin/firmware" method="post" onSubmit={this.handleSubmit}>
                        <button className="btn btn-default buttons-inline" type="submit"
                                aria-describedby="updateFirmwareHelp">
                            Update
                            Firmware
                        </button>
                    </form>
                    <form action="/admin/reset" method="post" onSubmit={this.handleSubmit}>
                        <button className="btn btn-default buttons-inline" type="submit"
                                aria-describedby="factoryResetHelp">
                            Factory
                            Reset
                        </button>
                    </form>
                </div>
            </div>
        );
    }

    handleSubmit(event) {
        console.log(event);
    }
}

export default Admin;
