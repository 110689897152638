import React, { Component } from 'react';
import { Route } from "react-router-dom";
import { Switch } from "react-router";

import Header from "./Header";
import Footer from "./Footer";
import Home from "./Home";
import Register from "./Register";
import Login from "./Login";
import Company from "./Company";
import Buy from "./Buy";
import Control from "./Control";
import NotFound from "./NotFound";

class App extends Component {

    render() {
        return (
            <div className="App">
                <Header/>
                <div className="content hydropod-content image-container">
                    <Switch>
                        <Route exact path='/' component={Home}/>
                        <Route exact path='/buy/' component={Buy}/>
                        <Route exact path='/login/' component={Login}/>
                        <Route exact path='/register/' component={Register}/>
                        <Route exact path='/company/' component={Company}/>
                        <Route exact path='/control/' component={Control}/>
                        <Route component={NotFound}/>
                    </Switch>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default App;