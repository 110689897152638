import React, { Component } from 'react';

class Buy extends Component {

    render() {
        return (
            <div className="vertical-center teaser-fullscreen">
                <div id="main-teaser" className="container text-center hydropod-teaser">
                    <div className="model"></div>
                    <div className="model"></div>
                    <p className="lead hydropod-lead white">Coming soon</p>
                </div>
            </div>
        );
    }
}

export default Buy;
