import React, {Component} from 'react';
import { Link } from "react-router-dom";

class Header extends Component {
    render() {
        return (
            <header className="app-header container">
                <nav className="navbar navbar-expand-lg navbar-dark">
                    <Link className="navbar-brand" to='/'>
                        <img src="../../img/hydropod_white.svg" width="200" height="50" alt="Hydropod logo"/>
                    </Link>
                    <button className="navbar-toggler"
                            type="button"
                            data-toggle="collapse"
                            data-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon" />
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav mr-auto">
                            <li className={this.getClassname('/buy/')}>
                                <Link className="nav-link" to='/buy/'>Buy</Link>
                            </li>
                            <li className={this.getClassname('/register/')}>
                                <Link className="nav-link" to='/register/'>Register</Link>
                            </li>
                            <li className={this.getClassname('/login/')}>
                                <Link className="nav-link" to='/login/'>Login</Link>
                            </li>
                            <li className={this.getClassname('/company/')}>
                                <Link className="nav-link" to='/company/'>Company</Link>
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>
        );
    }

    getClassname(route) {
        return route === window.location.pathname ? 'nav-item active' : 'nav-item';
    }
}

export default Header;
