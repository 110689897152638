import React, { Component } from 'react';
import echarts from "echarts";
import {
    calculateCelsuisTemperature,
    calculateRelativePercentage, fillLeadingZero,
    getCookie,
    getTimeframeQueryString,
    truncateDecimals
} from "./Utils";

class Chart extends Component {

    constructor() {
        super();
        this.chartContainer = React.createRef();
        this.updateDevices = this.updateDevices.bind(this);
        this.reloadChart = this.reloadChart.bind(this);
        this.handleReload = this.handleReload.bind(this);
    }

    componentDidMount() {
        this.sensorDataChart = echarts.init(this.chartContainer.current);
        window.onresize = () => {
            this.sensorDataChart.resize();
        };
        this.updateDevices();
    }

    render() {
        return (
            <div>
                <div id="chartContainer" ref={this.chartContainer}></div>
                <div className="container data-info">
                    <table className="data-table">
                        <tbody>
                            <tr>
                                <th>Sensor</th>
                                <th>Raw</th>
                                <th>Relative</th>
                            </tr>
                            <tr>
                                <td>Dampness</td>
                                <td id="dampness-value"></td>
                                <td id="dampness-value-rel"></td>
                            </tr>
                            <tr>
                                <td>Temperature</td>
                                <td id="temperature-value"></td>
                                <td id="temperature-value-rel"></td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="select-container">
                        <select id="devices" className="form-control form-control-sm" onChange={this.handleReload}></select>
                        <select id="timeframe" className="form-control form-control-sm" onChange={this.handleReload}>
                            <option value="today">Today</option>
                            <option value="week">This week</option>
                            <option value="lastweek">Last week</option>
                            <option value="month">This month</option>
                            <option value="quarter">This quarter</option>
                            <option value="halfyear">Half Year</option>
                        </select>
                    </div>
                </div>
            </div>
        );
    }

    handleReload() {
        this.reloadChart();
    }

    updateDevices() {
        const email = getCookie('email');
        const key = getCookie('key');

        if (email === "" || key === "") {
            window.location.href = "/login";
            return;
        }

        fetch('https://api.hydropod.de/v1/devices', {
            method: 'GET',
            headers: {
                "email": email,
                "key": key,
            }
        }).then(result => {
            if (result.status !== 200) {
                return;
            }
            result.json().then(devices => {
                const devicesSelect = document.getElementById('devices');
                let loading = false;
                for (var i = 0; i < devices.length; i++) {
                    const device = devices[i];
                    const opt = document.createElement('option');
                    opt.innerHTML = device['deviceName'];
                    opt.value = device['deviceId'];
                    devicesSelect.appendChild(opt);
                    if (devices[i] && devices[i]['deviceId'] && !loading) {
                        loading = true;
                        this.reloadChart(devices[i]['deviceId']);
                    }
                }
            });
        }).catch(error => {
            console.error(error);
        });
    }

    reloadChart() {

        this.sensorDataChart.showLoading();

        var deviceIdSelect = document.getElementById('devices');
        var deviceId = deviceIdSelect.options[deviceIdSelect.selectedIndex].value;
        var timeframeSelect = document.getElementById('timeframe');
        var timeframe = timeframeSelect.options[timeframeSelect.selectedIndex].value;

        fetch('https://api.hydropod.de/v1/data' + getTimeframeQueryString(timeframe), {
            method: 'GET',
            headers: {
                "deviceId": deviceId,
                "key": getCookie('key'),
            }
        }).then(result => {
            if (result.status !== 200) {
                return;
            }
            result.json().then(dataArray => {
                var dampnessData = [];
                var temperatureData = [];
                for (var i = 0; i < dataArray.length; i++) {
                    var data = dataArray[i];
                    var dampness = data['dampness'];
                    var dampnessThreshold = data['dampnessThreshold'];
                    var refDampnessLow = data['refDampnessLow'];
                    var refDampnessHigh = data['refDampnessHigh'];
                    var temperature = data['temperature'];
                    var relTemperature = calculateCelsuisTemperature(temperature);
                    var relDampness = calculateRelativePercentage(refDampnessLow, refDampnessHigh, dampness);
                    var relDampnessThreshold = Math.floor(calculateRelativePercentage(refDampnessLow, refDampnessHigh, dampnessThreshold));
                    var date = data['date'];
                    var dateVal = new Date(date);
                    var dateShort = [dateVal.getFullYear(), dateVal.getMonth(), dateVal.getDate()].join('/');
                    if (typeof date !== 'undefined' && typeof dampness !== 'undefined' && typeof temperature !== 'undefined') {
                        dampnessData.push({
                            name: dateVal,
                            value: [dateVal, relDampness],
                            type: 'line',
                            step: 'start'
                        });
                        temperatureData.push({
                            name: dateVal,
                            value: [dateVal, relTemperature],
                            type: 'line',
                            step: 'start'
                        });
                    }
                    if (i + 1 === dataArray.length) {
                        document.getElementById("dampness-value").innerText = truncateDecimals(dampness, 5) + " mV";
                        document.getElementById("dampness-value-rel").innerText = relDampness + " %";
                        document.getElementById("temperature-value").innerText = temperature;
                        document.getElementById("temperature-value-rel").innerText = calculateCelsuisTemperature(temperature) + " °C";
                    }
                }

                this.sensorDataChart.clear();
                this.sensorDataChart.setOption({
                    toolbox: {
                        show: true,
                        feature: {
                            dataZoom: {
                                yAxisIndex: 'none'
                            },
                            dataView: {readOnly: false},
                            magicType: {type: ['line', 'bar']},
                            restore: {},
                            saveAsImage: {}
                        }
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    tooltip: {
                        trigger: 'axis',
                        formatter: function (params) {
                            params = params[0];
                            var date = new Date(params.name);
                            return params.value[1] + '%  - ' + date.toLocaleDateString() + ' ' + fillLeadingZero(date.getHours()) + ':' + fillLeadingZero(date.getMinutes());
                        },
                        axisPointer: {
                            animation: false
                        }
                    },
                    xAxis: {
                        type: 'time'
                    },
                    yAxis: {
                        type: 'value',
                        max: 100,
                        min: 0,
                        axisLabel: {
                            formatter: '{value}%'
                        }
                    },
                    series: [{
                        name: 'dampness',
                        type: 'line',
                        step: 'line',
                        showSymbol: false,
                        hoverAnimation: false,
                        data: dampnessData,
                        markLine: {
                            data: [
                                {
                                    type: 'average',
                                    symbol: 'none',
                                    name: 'Dampness Threshold',
                                    yAxis: relDampnessThreshold,
                                    lineStyle: {
                                        normal: {
                                            color: "#00F"
                                        }
                                    },
                                    label: {
                                        normal: {
                                            show: true,
                                            position: 'middle',
                                            formatter: 'Dampness Threshold'
                                        }
                                    }
                                }
                            ]
                        }
                    }//,
//            {
//                name: 'temperature',
//                type: 'line',
//                step: 'line',
//                showSymbol: false,
//                hoverAnimation: false,
//                data: temperatureData
//            }
                    ]
                });
                this.sensorDataChart.hideLoading();
            });
        }).catch(error => {
            console.error(error);
        });
    }
}

export default Chart;
