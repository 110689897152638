
function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1);
        if (c.indexOf(name) !== -1) return c.substring(name.length, c.length);
    }
    return "";
}

function getTimeframeQueryString(timeframe) {

    var d = new Date();
    var start = new Date();
    var end = new Date();
    var day = d.getDay();
    if (timeframe === 'week') {
        start = new Date(d.getFullYear(), d.getMonth(), d.getDate() + (day === 0 ? -6 : 1) - day);
    } else if (timeframe === 'lastweek') {
        var tmp = new Date(d.getFullYear(), d.getMonth(), d.getDate() + (day === 0 ? -6 : 1) - day);
        start = new Date(tmp.setDate(tmp.getDate() - 6));
        end = new Date(tmp.setDate(tmp.getDate() + 6));
    } else if (timeframe === 'month') {
        start = new Date(d.getFullYear(), d.getMonth(), 1);
    } else if (timeframe === 'quarter') {
        start = new Date(new Date().setMonth(start.getMonth() - 4));
    } else if (timeframe === 'halfyear') {
        start = new Date(new Date().setMonth(start.getMonth() - 6));
    } else if (timeframe === 'year') {
        start = new Date(d.getFullYear(), 0, 1);
        end = new Date(d.getFullYear(), 11, 31);
    }

    start.setUTCHours(0, 0, 0, 0);
    end.setUTCHours(23, 59, 59, 999);

    return formatParams({
        from: start.toISOString(),
        to: end.toISOString()
    });
}

function fillLeadingZero(number) {
    return number < 10 ? "0" + number : "" + number;
}

function formatParams(params) {
  return "?" + Object
        .keys(params)
        .map(function(key) {
          return key+"="+params[key]
        })
        .join("&")
}

function truncateDecimals(number, digits) {
    var re = new RegExp("(\\d+\\.\\d{" + digits + "})(\\d)"), m = number.toString().match(re);
    return m ? parseFloat(m[1]) : number.valueOf();
}

function calculateRelativePercentage(min, max, value) {
    value = Math.max((max - value), 0) * 100 / (max - min);
    return truncateDecimals(value, 1);
}

function calculateCelsuisTemperature(tempFahrenheit) {
    var tempCelsius = (tempFahrenheit - 32) / 1.8;
    return truncateDecimals(tempCelsius, 1);
}

export {
    getCookie,
    getTimeframeQueryString,
    fillLeadingZero,
    calculateRelativePercentage,
    calculateCelsuisTemperature,
    truncateDecimals
}