import 'react-app-polyfill/ie11';
import 'core-js/es7/array';

import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from "react-router-dom";
import { withRouter } from "react-router";

import HydroApp from "./components/App";

import './Hydropod.css';

const App = withRouter(props => {
    if (window.location.hostname.indexOf("api.hydropod") !== -1) {
        return <HydroApp {...props} />;
    } else {
        return <HydroApp {...props} />
    }
});

ReactDOM.render(<BrowserRouter><App/></BrowserRouter>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
