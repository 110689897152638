import React, { Component } from 'react';

class Home extends Component {
    render() {
        return (
            <div className="content hydropod-content image-container">
                <div className="jumbotron main-teaser-background no-padding-top">
                    <div className="container">
                        <h1 className="display-3">404 not found</h1>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;
