import React, { Component } from 'react';

class Login extends Component {

    constructor() {
        super();
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    render() {
        return (
            <div className="container vertical-center teaser-fullscreen">
                <form className="credentials-form" action="https://api.hydropod.de/v1/login" method="post"
                      onSubmit={this.handleSubmit} name="login">
                    <p className="hydropod-lead text-center">Login</p>
                    <div className="form-group">
                        <small id="emailInfo" className="form-text text-muted">Email</small>
                        <input type="email" name="email" className="form-control" id="email"
                               aria-describedby="emailInfo" placeholder="Enter email address" />
                    </div>
                    <div className="form-group">
                        <small id="passwordHelp" className="form-text text-muted">Password</small>
                        <input type="password" name="key" className="form-control" id="key"
                               aria-describedby="passwordHelp" placeholder="Enter password" />
                    </div>
                    <br/>
                    <div className="form-group">
                        <button type="submit" className="btn btn-block btn-success">Submit</button>
                    </div>
                </form>
            </div>
        )
    }

    handleSubmit(event) {
        event.preventDefault();
        const form = event.target;
        let params = '';
        for (var i = 0, element; element = form.elements[i++];) {
            if (element.name !== '') {
                if (params !== '') {
                    params += '&';
                }
                params += element.name + "=" + element.value;
            }
        }
        fetch('https://api.hydropod.de/v1/login', {
            method: 'POST',
            headers: {"Content-type": "application/x-www-form-urlencoded"},
            body: params,
        })
        .then(resp => {
            if (resp.status === 200) {
                resp.json().then(login => {
                    document.cookie = "email=" + login["email"] + "; expires=0; path=/";
                    document.cookie = "key=" + login["key"] + "; expires=0; path=/";
                    window.location.href = "/control";
                });
            }
        }).catch(error => {
            console.error(error);
        });
    }
}

export default Login;
