import React, { Component } from 'react';

class Footer extends Component {
    render() {
        return (
            <footer className="footer">
                <div className="container">
                    <p>Copyright &copy; 2016-2020 Christian Mahler</p>
                    <p>&nbsp;</p>
                </div>
            </footer>
        );
    }
}

export default Footer;
