import React, { Component } from 'react';

class Register extends Component {

    constructor() {
        super();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleRedirect = this.handleRedirect.bind(this);
        this.resultText = React.createRef();
    }

    render() {
        return (
            <div className="container vertical-center teaser-fullscreen">
                <form className="credentials-form" action="https://api.hydropod.de/v1/register" method="post"
                      onSubmit={this.handleSubmit} name="register">
                    <p className="hydropod-lead text-center">Register</p>
                    <div className="form-group">
                        <small id="emailInfo" className="form-text text-muted">Email</small>
                        <input type="email" name="email" className="form-control" id="email"
                               aria-describedby="emailInfo" placeholder="Enter email address" />
                    </div>
                    <div className="form-group">
                        <small id="passwordHelp" className="form-text text-muted">Password</small>
                        <input type="password" name="key" className="form-control" id="key"
                               aria-describedby="passwordHelp" placeholder="Enter password" />
                    </div>
                    <div className="form-group">
                        <small id="deviceIdHelp" className="form-text text-muted">Device Id</small>
                        <input type="text" name="deviceId" className="form-control" id="deviceId"
                               aria-describedby="deviceIdHelp" placeholder="Enter device ID" />
                    </div>
                    <div className="form-group">
                        <small id="deviceNameHelp" className="form-text text-muted">Device Name</small>
                        <input type="text" name="deviceName" className="form-control" id="deviceName"
                               aria-describedby="deviceNameHelp" placeholder="Choose a device name" />
                    </div>
                    <br/>
                    <div className="form-group">
                        <button type="submit" className="btn btn-block btn-success">Submit</button>
                    </div>

                    <div className="modal fade" id="register_modal" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog  modal-sm" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">HydroPod registration</h5>
                                    <p ref={this.resultText}></p>
                                    <button id="close-button" type="button" className="close" data-dismiss="modal"
                                            aria-label="Close" onClick={this.handleRedirect}>
                                        <span aria-hidden="true">Close &times;</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }

    handleRedirect() {
        window.location.href = "/login";
    }

    handleSubmit(event) {
        event.preventDefault();
        const form = event.target;
        let params = '';
        for (let i = 0, element; element = form.elements[i++];) {
            if (element.name !== '') {
                if (params !== '') {
                    params += '&';
                }
                params += element.name + "=" + element.value;
            }
        }
        fetch('https://api.hydropod.de/v1/register', {
            method: 'POST',
            headers: {"Content-type": "application/x-www-form-urlencoded"},
            body: params,
        }).then(result => {
            if (result.status >= 200 && result.status < 300) {
                this.resultText.current.innerHTML = 'Registration succesful. You can login now.';
            } else {
                this.resultText.current.innerHTML = 'Registration failed. Please try again.';
            }
        }).catch(error => {
            console.error(error);
        });
    }
}

export default Register;
