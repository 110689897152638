import React, { Component } from 'react';

class Home extends Component {

    render() {
        return (
            <div className="vertical-center teaser-fullscreen">
                <div id="main-teaser" className="container text-center hydropod-teaser">
                </div>
            </div>
        );
    }
}

export default Home;
