import React, { Component } from 'react';

class Company extends Component {

    render() {
        return (
            <div className="vertical-center teaser-fullscreen">
                <div id="main-teaser" className="container hydropod-teaser company-info">
                    <div className="container">
                        <h5>Company Contact</h5>
                        <p>Christian Mahler<br/>
                            Email: <a className="company-email" href="mailto:christian.mahler@cunum.com">christian.mahler@cunum.com</a><br/>
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default Company;
